import { requireOneOf } from '@nordinvestments/nord-storybook/dist/utilities/propTypes'
import { requiredBy } from 'airbnb-prop-types'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const GatsbyInternalLink = React.forwardRef(
  ({ href, to, external, children, ...props }, ref) => {
    const absoluteUrl =
      href && (href.startsWith('http://') || href.startsWith('https://'))

    if (external || absoluteUrl) {
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      return (
        <a href={href || to} ref={ref} {...props}>
          {children}
        </a>
      )
    }

    return (
      <>
        <Link to={to || href} ref={ref} {...props}>
          {children}
        </Link>
      </>
    )
  }
)

GatsbyInternalLink.propTypes = {
  href: requiredBy('external', PropTypes.string),
  to: requireOneOf(PropTypes.string, 'external'),
  children: PropTypes.node.isRequired,
  external: PropTypes.bool
}

GatsbyInternalLink.defaultProps = {
  href: undefined,
  to: undefined,
  external: undefined
}

GatsbyInternalLink.displayName = 'GatsbyInternalLink'

export default GatsbyInternalLink
