import InternalLink from '@nordinvestments/nord-storybook/dist/components/InternalLink'
import { resetCount } from '@nordinvestments/nord-storybook/dist/utilities/nextCount'

import GatsbyInternalLink from '../components/GatsbyInternalLink'

const setupNord = () => {
  InternalLink.defaultProps.as = GatsbyInternalLink

  resetCount()
}

export default setupNord
