/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import setupNord from './src/configuration/setupNord'

import './src/assets/styles/index.scss'

// eslint-disable-next-line import/prefer-default-export
export const onClientEntry = () => {
  setupNord()
}

export const shouldUpdateScroll = ({
  prevRouterProps: { location: prevLocation },
  routerProps: { location },
  getSavedScrollPosition,
  loadPage
}) => {
  loadPage(location.pathname).then((page) => {
    // eslint-disable-next-line no-param-reassign
    page.json.pageContext.scrollPosition = getSavedScrollPosition(location)
  })

  // Fix because of page banner padding
  const prevScrollPosition = getSavedScrollPosition(prevLocation)
  if (
    prevScrollPosition &&
    (prevScrollPosition[1] === 45 || prevScrollPosition[1] === 0)
  ) {
    window.scrollTo(0, 0)
  }

  return false
}
