import IconButton from '@nordinvestments/nord-storybook/dist/components/IconButton'
import NavMenu from '@nordinvestments/nord-storybook/dist/components/NavMenu'
import { getConfig } from '@nordinvestments/nord-storybook/dist/configuration'
import React from 'react'
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import OnboardingLink from '../OnboardingLink'

const webappUrl = getConfig('domains.app')
const blogUrl = getConfig('domains.blog')
const contactInformation = getConfig('contactInformation')

const Header = () => (
  <header>
    <NavMenu
      leftNav={[
        <NavMenu.Item>
          <NavMenu.Link to="/vi-tilbyder" animated>
            Vi tilbyder
          </NavMenu.Link>
        </NavMenu.Item>,
        <NavMenu.Item>
          <NavMenu.Link to="/afkast" animated>
            Afkast
          </NavMenu.Link>
        </NavMenu.Item>,
        <NavMenu.Item>
          <NavMenu.Link to="/om" animated>
            Om
          </NavMenu.Link>
        </NavMenu.Item>,
        <NavMenu.Item>
          <NavMenu.Link to="/priser" animated>
            Priser
          </NavMenu.Link>
        </NavMenu.Item>,
        <NavMenu.Item>
          <NavMenu.Link href={blogUrl} animated>
            Blog
          </NavMenu.Link>
        </NavMenu.Item>
      ]}
      rightNav={[
        <NavMenu.Item className="mt-3 order-lg-1 mt-lg-0 font-size-xs">
          <NavMenu.Link href={contactInformation.phoneLink} external animated>
            {contactInformation.phone}
          </NavMenu.Link>
        </NavMenu.Item>,
        <NavMenu.Item className="mt-3 order-lg-1 mt-lg-0 font-size-xs">
          <NavMenu.Link href={`${webappUrl}/brugere/logind`} external animated>
            Log ind
          </NavMenu.Link>
        </NavMenu.Item>,
        <NavMenu.Item className="mt-3 order-lg-2 mt-lg-0">
          <OnboardingLink
            component={IconButton}
            variant="success"
            icon={ArrowRight}
            size="0.75rem"
          >
            Kom i gang
          </OnboardingLink>
        </NavMenu.Item>
      ]}
    />
  </header>
)

export default Header
