/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import ApplicationWrapper from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper'
import CookieConsent from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/CookieConsent'
import EnvironmentBar from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/EnvironmentBar'
import ErrorBoundary from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/ErrorBoundary'
import Footer from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/Footer'
import Header from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/Header'
import Intercom from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/Intercom'
import PageLayout from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/PageLayout'
import PageTransition from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/PageTransition'
import { resetCount } from '@nordinvestments/nord-storybook/dist/utilities/nextCount'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import pushToDataLayer from '../configuration/pushToDataLayer'

import Navigation from './Header'

const handleBeforePageChange = () => {
  resetCount()
}

const pushExperimentData = () => {
  pushToDataLayer({
    experimentId: process.env.GATSBY_GOOGLE_OPTIMIZE_EXPERIMENT_ID,
    variationId: process.env.GATSBY_GOOGLE_OPTIMIZE_VARIATION_ID
  })
}

const Layout = ({ children, location, pageResources }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const handlePageChange = ({ key, previousKey }) => {
    const previousScrollPosition = pageResources.json.pageContext.scrollPosition
    window.scrollTo(...(previousScrollPosition || [0, 0]))

    if (window.dataLayer) {
      window.dataLayer.push({ event: 'optimize.activate' })
      window.dataLayer.push({
        event: 'pageview',
        path: key,
        previousPath: previousKey
      })
    }
  }

  useEffect(() => {
    pushExperimentData()
  }, [])

  return (
    <PageTransition
      beforePageChange={handleBeforePageChange}
      onPageChange={handlePageChange}
      pageKey={location.pathname}
    >
      <ApplicationWrapper
        plugins={[
          ErrorBoundary,
          Intercom,
          EnvironmentBar,
          (props) => <CookieConsent onAccept={pushExperimentData} {...props} />,
          (props) => (
            <Header
              {...props}
              content={<Navigation siteTitle={data.site.siteMetadata.title} />}
            />
          ),
          (props) => <PageLayout {...props} fluid />,
          Footer
        ]}
      >
        {children}
      </ApplicationWrapper>
    </PageTransition>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  pageResources: PropTypes.shape({
    json: PropTypes.shape({
      pageContext: PropTypes.shape({
        scrollPosition: PropTypes.arrayOf(PropTypes.number)
      })
    })
  }).isRequired
}

export default Layout
