import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import { getConfig } from '@nordinvestments/nord-storybook/dist/configuration'
import PropTypes from 'prop-types'
import React from 'react'

const onboardingUrl = getConfig('domains.onboarding')

const OnboardingLink = React.forwardRef(
  ({ children, path, component: Component, ...props }, ref) => {
    return (
      <Component href={`${onboardingUrl}/${path}`} ref={ref} {...props}>
        {children}
      </Component>
    )
  }
)

OnboardingLink.propTypes = {
  component: PropTypes.elementType,
  children: PropTypes.node,
  path: PropTypes.string
}

OnboardingLink.defaultProps = {
  children: 'Gratis investeringsplan',
  component: BaffleButton,
  path: ''
}

OnboardingLink.displayName = 'OnboardingLink'

export default OnboardingLink
