// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-pages-empty-page-jsx": () => import("./../src/components/pages/EmptyPage.jsx" /* webpackChunkName: "component---src-components-pages-empty-page-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-afkast-jsx": () => import("./../src/pages/afkast.jsx" /* webpackChunkName: "component---src-pages-afkast-jsx" */),
  "component---src-pages-ansvarlig-investering-jsx": () => import("./../src/pages/ansvarlig-investering.jsx" /* webpackChunkName: "component---src-pages-ansvarlig-investering-jsx" */),
  "component---src-pages-etf-jsx": () => import("./../src/pages/etf.jsx" /* webpackChunkName: "component---src-pages-etf-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-investering-jsx": () => import("./../src/pages/investering.jsx" /* webpackChunkName: "component---src-pages-investering-jsx" */),
  "component---src-pages-investor-jsx": () => import("./../src/pages/investor.jsx" /* webpackChunkName: "component---src-pages-investor-jsx" */),
  "component---src-pages-karriere-jsx": () => import("./../src/pages/karriere.jsx" /* webpackChunkName: "component---src-pages-karriere-jsx" */),
  "component---src-pages-om-jsx": () => import("./../src/pages/om.jsx" /* webpackChunkName: "component---src-pages-om-jsx" */),
  "component---src-pages-pension-jsx": () => import("./../src/pages/pension.jsx" /* webpackChunkName: "component---src-pages-pension-jsx" */),
  "component---src-pages-priser-jsx": () => import("./../src/pages/priser.jsx" /* webpackChunkName: "component---src-pages-priser-jsx" */),
  "component---src-pages-privatlivspolitik-jsx": () => import("./../src/pages/privatlivspolitik.jsx" /* webpackChunkName: "component---src-pages-privatlivspolitik-jsx" */),
  "component---src-pages-vi-tilbyder-jsx": () => import("./../src/pages/vi-tilbyder.jsx" /* webpackChunkName: "component---src-pages-vi-tilbyder-jsx" */),
  "component---src-pages-virksomhed-jsx": () => import("./../src/pages/virksomhed.jsx" /* webpackChunkName: "component---src-pages-virksomhed-jsx" */),
  "component---src-pages-wealth-jsx": () => import("./../src/pages/wealth.jsx" /* webpackChunkName: "component---src-pages-wealth-jsx" */)
}

